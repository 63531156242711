import React from "react";
import "../Assets/Styles/Cart.css"
import { Avatar } from "@mui/material";
import { teal } from "@mui/material/colors";

const CartHeader = () => {
  return (
    <div className="CartProfile">
      <div className="avatar">
        <Avatar
          alt="Salt"
          src="https://img.freepik.com/premium-photo/assorted-indian-recipes-food-various_79295-7226.jpg?size=626&ext=jpg&ga=GA1.2.1466210577.1673936241"
          sx={{ bgcolor: teal[500], width: 150, height: 150 }}
        >
          SALT
        </Avatar>
      </div>
    </div>
  );
};

export default CartHeader;
