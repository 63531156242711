import React from "react";
import "../Assets/Styles/OrderStatus.css";
import cooking from '../Assets/Images/foo.png'
import horn from '../Assets/Images/horn.png'

const OrderStatus = () => {
  return (
    <div>
      <div className="order_title">
        <h2 className="heading">Order Status</h2>
        <h4 className="invoice">INVOICE : 123D52</h4>
      </div>
      <div>
        <img
          className="cooking_img"
          src={cooking}
        />
      </div>

      <div class="order-track">
        <div class="order-track-step">
          <div class="order-track-status">
            <span class="order-track-status-dot"></span>
            <span class="order-track-status-line"></span>
          </div>
          <div class="order-track-text">
            <p class="order-track-text-stat">Order Received</p>
            <span class="order-track-text-sub">21st November, 2019</span>
          </div>
        </div>
        <div class="order-track-step">
          <div class="order-track-status">
            <span class="order-track-status-dot"></span>
            <span class="order-track-status-line"></span>
          </div>
          <div class="order-track-text">
            <p class="order-track-text-stat">Preparing Order</p>
            <span class="order-track-text-sub">21st November, 2019</span>
          </div>
        </div>
        <div class="order-track-step">
          <div class="order-track-status">
            <span class="order-track-status-dot"></span>
          </div>
          <div class="order-track-text">
            <p class="order-track-text-stat">Pick up your Order</p>
            <span class="order-track-text-sub">Finish time is 30 min</span>
          </div>
        </div>
      </div>
      <div className="order_notify">
        <hr />
        <h4 className="order_notify_text">
          On Which side of the provider are you at?
        </h4>
        <input
          className="order_notify_input"
          placeholder="Choose Your Vehicle Position"
        />
      </div>
      <div className="order_press">
        <h5 className="order_press_text">
          Press the icon below to notify the restuarant about your presence
        </h5>
        <a><img className="horn_img " src={horn}/></a>
      </div>
    </div>
  );
};

export default OrderStatus;
