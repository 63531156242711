import React from 'react'
import FloatinCartButton from './FloatinCartButton'
import Header from './Header'
import Menu from './Menu'

const Provider = () => {
  return (
    <div className='header'>
        <Header/>
        <Menu/>
        <FloatinCartButton/>
        </div>
  )
}

export default Provider