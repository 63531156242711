import React from 'react'
import '../Assets/Styles/Header.css'
import ProvidersDetails from './ProvidersDetails'



const Header = () => {
  return (
    <div >
        <div className='fdimageDiv'>
            <img className='img' src='https://media.istockphoto.com/id/1188412964/photo/hamburger-with-cheese-and-french-fries.jpg?s=1024x1024&w=is&k=20&c=ZxbMWTEcc5mVmCMRshVIkPxcCR7LJQpUb7CEOA11Jvw=' alt='Food'/>
        </div>
        <ProvidersDetails/>
    
    </div>
  )
}

export default Header