import React, { useState } from "react";
import "../Assets/Styles/MyOrder.css";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const MyOrder = () => {
  const [visible, setVisible] = useState(false);
  function details() {
    setVisible(!visible);
  }

  return (
    <div>
      <div className="my_order_title">
        <h2>My Order</h2>
      </div>
      <div>
        <Card className="order_card" >
          <CardContent className="order_main_content">
            <CardContent className="order_card_content_1">
              <Typography variant="h6" component="div">
                Order No #145333
              </Typography>
              <Typography variant="caption" color="text.secondary">
                10 Nov, 10:30 PM
              </Typography>
            </CardContent>
            <CardContent sx={{ margin: 0 }} className="order_card_content_2">
              <Typography
                color="text.secondary"
                variant="subtitle2"
                gutterBottom
              >
                Location : 1st Cross, King Saud St, Dubai,UAE
              </Typography>
              <Typography variant="h6" color="#539a3f">
                Order Completed
              </Typography>
            </CardContent>
          </CardContent>
          <CardActions className="pickup_btn"></CardActions>
        </Card>
        <div className="order_detail_hide">
          <Button
            onClick={details}
            sx={{ border: "none", boxShadow: "none", fontSize: 10 }}
          >
            Click to view details
          </Button>
          {visible ? (
            <div className="order_detail_hide_div">
              <div>
                <h4 className="item_detail_title">Item Details</h4>
                <p className="item_detail_des">01 x Chicken Burger</p>
              </div>
              <div>
                <h4 className="total_amt_title">Total Amount</h4>
                <p className="total_amt_des">AED 2400</p>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
const Text = () => <div>I will disappear, true Magic</div>;

export default MyOrder;
