import React from 'react'
import '../Assets/Styles/Menu.css'
import { Button } from '@mui/material'

const AddCart = () => {
  return (
    <div className='addcartbtn'><Button variant="outlined">Add +</Button></div>
  )
}

export default AddCart