import React from "react";
import Fab from "@material-ui/core/Fab";
import KeyboardControlKeyIcon from "@mui/icons-material/KeyboardControlKey";
import '../Assets/Styles/FloatingCart.css'
import Zoom from '@material-ui/core/Zoom'
import { Typography } from "@material-ui/core";
import { useNavigate } from "react-router-dom";

const FloatinCartButton = () => {
    const navigate = useNavigate()

    function floatingCart(){
        navigate('/floatingCart')

    }
  return (
    <div className="floating">
      <div className="floatingBtn">
        <Zoom in={true} timeout={{enter:500,exit:500}} unmountOnExit>
      <Fab style={{backgroundColor:' #23A7DF'}} size='large' onClick={floatingCart}>
        <KeyboardControlKeyIcon  style={{color:'white'}}/>
      </Fab>
      </Zoom>
      </div>
    <div className="totalAmt">
        <Typography variant="h6">TOTAL AMOUNT</Typography>
        <Typography>100.00 AED</Typography>
    </div>


    </div>
  );
};

export default FloatinCartButton;
