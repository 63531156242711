import React from "react";
import "../Assets/Styles/Cart.css";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { FaAngleDown } from "react-icons/fa";

const MyItems = () => {
  return (
    <div>
      <div className="mycartItem">
        <h2 className="mycarttitle">
          {" "}
          <FaAngleDown /> My Cart
        </h2>
        <Card
          sx={{
            display: "flex",
            borderRadius: 2,
            backgroundColor: "transparent",

          }}
        >
          <CardMedia
            component="img"
            sx={{ width: 100 }}
            image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcROfI3RiYKiNmYqh-SMtaPeLWE-TyGHMkDDNOo_cpH0pw&s"
            alt="Live from space album cover"
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginLeft: 2,
             
            }}
      
          >
            <Typography variant="h5" color="white" sx={{ fontSize: 15 }}>
              Grilled
            </Typography>
            <div className="itemAmt">
              <Typography variant="h5" color="white" sx={{ fontSize: 18 }}>
                AED 20
              </Typography>
            </div>

            <div className="itembtn">
              <button className="btn1">-</button>
              <button className="btn2">+</button>
              <input placeholder="1" className="itemtext" />
            </div>
          </Box>
        </Card>
      </div>
    </div>
  );
};

export default MyItems;
