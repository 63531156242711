import React from "react";
import "../Assets/Styles/Header.css";


const ProviderSearch = () => {
  return (
    <div className="search">
      <div className="search_bar">
        <div className="search_i">
          <h3 className="title">
            CHOOSE YOUR LOCATION EXPLORE HOMEBUSINESS AROUND YOU
          </h3>
          <form action="/" method="get" className="search_form">
        <input className="search_input"
            type="text"
            placeholder="Search For a Provider"
            name="s" 
        />
        <button className="enter_btn" type="submit">Enter</button>
    </form>   
        </div>
      </div>
      <h3 className="copywrite">Copywrite 2020 All rights reserved Powered by Coderythm Technology</h3>
    </div>
  );
};

export default ProviderSearch;
