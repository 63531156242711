import React, { useState } from 'react'
import '../Assets/Styles/OTPLogin.css'
import { useNavigate } from 'react-router-dom';

const OTPLogin = () => {
    const [otp,setOtp] = useState(new Array(4).fill(""))
    const handleChange = (element,index) => {
        if(isNaN(element.value)) return false;

        setOtp([...otp.map((d,idx)=> (idx === index) ? element.value : d)])

        //Focus next input 
        if(element.nextSibling){
            element.nextSibling.focus();
        }

    }
    const navigate = useNavigate();
    function providerClick (){
        navigate('/provider')
     }
    function profile (){
        navigate('/profile')
    }
  return (
    <div className='OTPscreen'>
        
        <div className="otp">
            <span className='title'><h2>Verify Your Phone Number</h2>
        <h4 className='subTitle'>Enter your OTP code here</h4></span>
        
            {
                otp.map((data,index) => {
                    return <input 
                            className='otp-field' 
                            type='text' 
                            name='otp' 
                            maxLength='1' 
                            key={index} 
                            value={data}
                            onChange={e => handleChange(e.target,index)}
                            onFocus={e => e.target.select()}/>
                })
            }
            <div className='resetBtnDiv'><button className='resetBtn'>Resend OTP</button></div>
            

            
                {/* <button className='btn' onClick={e => setOtp([...otp.map(v => "")])}>Clear</button> */}
                <button  className='verifybtn' onClick={providerClick} > VERIFY NOW</button>

            <div className='otherBtnDiv'><button className='otherBtn' onClick={profile}>Or try a differebt Option</button></div>
                <p>OTO Entered - {otp.join("")}</p>
            
            
        </div>
    </div>
  )
}

export default OTPLogin