import React from 'react'
import "../Assets/Styles/Cart.css"
import CartHeader from './CartHeader'
import MyCart from './MyCart'

const FloatingCart = () => {
  return (
    <div className='CartBg'>
      <CartHeader/>
      <MyCart/>
    </div>
  )
}

export default FloatingCart