
import './App.css';
import Signin from './Login/Signin';
import Provider from './Providers/Provider'
import { Route,Routes } from 'react-router-dom';
import OTPLogin from './Login/OTPLogin';
import FloatingCart from './Cart/FloatingCart'
import MenuItemDetails from './Providers/MenuItemDetails';
import Profile from './Profile/Profile';
import ProviderSearch from './Providers/ProviderSearch';
import OrderStatus from './Cart/OrderStatus';
import MyOrder from './Cart/MyOrder';


function App() {
  return (
    <div className='App'>

      <Routes>
        <Route path='/' element={<Signin/>}/>
        <Route path='/otp' element={<OTPLogin/>}/>
        <Route path='/provider' element={<Provider/>}/>
        <Route path='/floatingCart' element={<FloatingCart/>}/>
        <Route path='/menuItemDetails' element={<MenuItemDetails/>}/>
        <Route path='/profile' element={<Profile/>}/>
        <Route path='/search' element={<ProviderSearch/>}/>
        <Route path='/orderstatus' element={<OrderStatus/>}></Route>
        <Route path='/myorder' element={<MyOrder/>}/>
      </Routes>
    </div>
  )
  
}

export default App;
