import React, { useState } from "react";
import "../Assets/Styles/Menu.css";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import CircleChecked from '@material-ui/icons/CheckCircleOutline';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';



const MenuItemDetails = () => {
 const navigate = useNavigate();
 function addToCart (){
    navigate('/floatingCart')
 }
  const [checked, setChecked] = React.useState([1]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  return (
    <div>
      <div className="item-img">
        <img src="https://media.istockphoto.com/id/1188412964/photo/hamburger-with-cheese-and-french-fries.jpg?s=612x612&w=0&k=20&c=lmJ0qUjC3FtCrWOGU0hWvqBgXcKZ1imiXKOMuHRfFH8=" />
      </div>
      <div className="itemDetails">
        <div className="itemDes">
          <h1 className="item-title">Grilled Chicken</h1>
          <h5 className="item-desc-title">DESCRIPTION</h5>
          <h6 className="item-description">
            In publishing and graphic design, Lorem ipsum is a placeholder text
            commonly used to demonstrate the visual form of a document
          </h6>
        </div>
        <div className="itemQnty">
          <h5 className="item-quantity-title">QUANTITY</h5>
          <div className="item-quantity">
            <div>
              <h4 className="item-count">1 Item</h4>
            </div>
            <div className="btns">
              <button className="btn-dec">-</button>
              <button className="btn-inc">+</button>
              <input className="item-num" placeholder="1"/>
            </div>
          </div>
        </div >
        <div className="item-extra">
          <h1 className="item-extra-extra">EXTRAS</h1>
          <div className="item-extra-list">
          <List
            dense
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          >
            {[0, 1, 2, 3].map((value) => {
              const labelId = `checkbox-list-secondary-label-${value}`;
              return (
                <ListItem
                  key={value}
                  secondaryAction={
                    <Checkbox
                      icon={<CircleUnchecked />}
                      checkedIcon={<CircleCheckedFilled />}
                      edge="end"
                      onChange={handleToggle(value)}
                      checked={checked.indexOf(value) !== -1}
                      inputProps={{ "aria-labelledby": labelId }}
                      
                    />
                  }
                  disablePadding
                >
                  <ListItemButton>
                    <ListItemText
                      id={labelId}
                      primary={`Modifier  ${value + 1}`}
                    />

                   <Button>+ AED 35.00</Button>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>


          </div>
          
        </div>
        <div className="addCartbtn">
          <button onClick={addToCart} className="itemAddCartBtn">Add to Cart</button>
        </div>
      </div>
    </div>
  );
};

export default MenuItemDetails;
