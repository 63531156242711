import React from 'react'
import '../Assets/Styles/Signin.css'
import {
  TextField,
  Button
} from '@mui/material';
import { Grid } from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';
import { useNavigate } from 'react-router-dom';


const Signin = () => {
 const navigate = useNavigate();
 function handleClick (){
    navigate('/otp')
 }
  return (
    <div className='signin'>
    <div className='form'>
      <div className='title'>

      <h1>Welcome Back</h1>
      <h4>sign in to continue</h4>
      </div>
      
      <form >
                <Grid container alignItems="center" justify="center" direction="column" >
                    <Grid item>
                        <TextField sx={{borderBottomColor: '0.5px solid white'}}
                            id="standard-basic" label="PHONE NUMBER" variant="standard"   InputProps={{
                              startAdornment: <InputAdornment position="start">+971</InputAdornment>,
                              style: {
                                color: "white",
                                
                            }
                            }}
                            
                        />
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" type="submit" style={{
                            backgroundColor: "#2C6374",
                            margin: "5px",
                            borderRadius: 20,
                            width:"250px",
                            marginTop:"50px"
                        }} onClick={handleClick}>
                            GET OTP
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained"  type="submit" style={{
                            backgroundColor: "#dddddd",
                            margin: "5px",
                            borderRadius: 20,
                            width:"250px",
                            color:"#8b8b8b",
                            marginBottom:"20px"
                        }}>
                            CONTINUE AS GUEST
                        </Button>
                    </Grid>
                    <h3 className='hr-lines'>OR GET OTP VIA</h3>


                    <Grid item>
                        <Button variant="contained" color="primary" type="submit" style={{
                            backgroundColor: "#2C6374",
                            margin: "5px",
                            borderRadius: 20,
                            width:"250px",
                            marginTop:"20px"
                        }}>
                            GOOGLE
                        </Button>
                    </Grid>

                    <Grid item>
                        <Button variant="contained" color="primary" type="submit" style={{
                            backgroundColor: "#2C6374",
                            margin: "5px",
                            borderRadius: 20,
                            width:"250px",
                        }}>
                            FACEBOOK
                        </Button>
                    </Grid>
                </Grid>
            </form>
     
    </div>
    </div>
  )
}

export default Signin