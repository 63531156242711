import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import "../Assets/Styles/Menu.css";
import AddCart from "./AddCart";
import { Link } from "react-router-dom";
import MenuItemDetails from "./MenuItemDetails";

const MenuCard = () => {
  return (
    <div className="menucard">
      {/* <h1 className="menu-category">Arabs</h1> */}
      <div className="menulist">
        <Card sx={{ display: "flex" }}>
          <Link component={<MenuItemDetails/>} to={'/menuItemDetails'}>
          <CardMedia
            component="img"
            sx={{ maxWidth:130,maxHeight:130, borderRadius: 4 }}
            image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcROfI3RiYKiNmYqh-SMtaPeLWE-TyGHMkDDNOo_cpH0pw&s"
            alt="Live from space album cover"
          /></Link>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginLeft: 3,
              marginTop: 3,
            }}
          >
            <div className="menu-item-details">
              <Typography variant="h5" color="black" fontSize={14}>
                Grilled Chicken
              </Typography>
              <Typography variant="body2" color="text.secondary" fontSize={10}>
                10 Reviews
              </Typography>
              <Typography variant="h6" color="black" fontSize={13}>
                AED 20
              </Typography>
              <Typography variant="body2" color="text.secondary" fontSize={12}>
                Use Code and get 10% off!
              </Typography>
            </div>
          </Box>
        </Card>
      </div>
      <AddCart />
    </div>
  );
};

export default MenuCard;
