import React from 'react'
import '../Assets/Styles/Header.css'
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';


const ProvidersDetails = () => {
  return (
    <div className='card'>
      
    <Card sx={{borderRadius:2,boxShadow:"0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)"}}>
  <div className='card-img'><CardHeader
    avatar={
      <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
        salt
      </Avatar>
    }
    title="SALT"
    subheader="Open now - 4pm - 1am (Today)"
  />
  </div>
  <div className='card-content'>
  <CardContent>
  <Typography gutterBottom variant="body2" component="div">
      DESCRIPTION
    </Typography>
    <Typography variant="body2" color="text.secondary">
      This impressive paella is a perfect party dish and a fun meal to cook
      together with your guests.
    </Typography>
  </CardContent>
  </div>
  
</Card>
    </div>
  )
}

export default ProvidersDetails