import React from "react";
import '../Assets/Styles/Menu.css'
import MenuCard from "./MenuCard";


const Menu = () => {
 
  return (
    <div className="menulists">
        <MenuCard/>
        
      
    </div>
  );
};

export default Menu;
